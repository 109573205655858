import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

import FullWidthImage from "../components/FullWidthImage";
import { getImage } from "gatsby-plugin-image";

const Menu = ({ areas }) => {
  const ret = areas.map((area, count) => {
    return (
      <tr key={area.node.id}>
        <td>
          <a href={"#area" + count}>{area.node.area}</a>
        </td>
      </tr>
    );
  });
  return ret;
};

const PrefEntry = ({ pref_by_area, medals }) => {
  const ret = pref_by_area.map((pref) => {
    const medal_by_pref = medals.filter((medal) => {
      return medal.node.Pref === pref.node.pref;
    });

    const medal_entry = medal_by_pref.map((medal) => (
      <tr key={medal.node.id}>
        <td>{medal.node.Place}</td>
        <td>{medal.node.Date}</td>
        <td>{medal.node.Note}</td>
      </tr>
    ));

    return (
      <div key={pref.node.id}>
        <h5 className="is-size-5 has-text-weight-bold has-text-primary">
          {pref.node.pref}
        </h5>
        <table className="table is-bordered is-striped">
          <tbody>{medal_entry}</tbody>
        </table>
        <a href="#area0">上へ</a>
      </div>
    );
  });

  return ret;
};

// eslint-disable-next-line
export const MedalPageTemplate = ({
  title,
  content,
  contentComponent,
  lang,
  image,

  medals,
  totalcount,
  prefs,
  areas,
}) => {
  const PageContent = contentComponent || Content;
  const heroImage = getImage(image) || image;

  const ret = areas.map((area, count) => {
    const pref_by_area = prefs.filter((pref) => {
      return pref.node.area === area.node.area;
    });

    return (
      <div className="box" key={`area-${count}`}>
        <h4 className="is-size-4 has-text-primary">
          <a name={"area" + count}></a>
          {area.node.area}
        </h4>
        <PrefEntry pref_by_area={pref_by_area} medals={medals} />
      </div>
    );
  });

  return (
    <div>
      <Navbar lang={lang} />
      <FullWidthImage img={heroImage} title={title} />
      <section className="section section--gradient">
        <Helmet title={`${title} | 飯山研究室`} />
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="section">
                <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                  {title}
                </h2>
                <PageContent className="content" content={content} />
                <h3 className="title is-size-4 has-text-weight-bold is-bold-light">
                  収集済みメダル一覧
                </h3>
                <p>現在のメダル枚数（重複有）：{totalcount}枚</p>
                <table className="table">
                  <thead>
                    <tr key="area">
                      <th>地域</th>
                    </tr>
                  </thead>
                  <tbody>
                    <Menu areas={areas} />
                  </tbody>
                </table>
                {ret}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer lang={lang} />
    </div>
  );
};

MedalPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const MedalPage = ({ data }) => {
  const {
    markdownRemark: post,
    allMedalXlsxMedal: medals,
    allMedalXlsxPref: prefs,
    allMedalXlsxArea: areas,
  } = data;

  return (
    <Layout>
      <MedalPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        lang={post.frontmatter.lang}
        image={post.frontmatter.image}
        content={post.html}
        medals={medals.edges}
        totalcount={medals.totalCount}
        prefs={prefs.edges}
        areas={areas.edges}
      />
    </Layout>
  );
};

MedalPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default MedalPage;

export const medalPageQuery = graphql`
  query medalPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        lang
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    }
    allMedalXlsxMedal(sort: { fields: Place }) {
      edges {
        node {
          Date(formatString: "YYYY/MM/DD")
          Note
          Place
          Pref
          filename1
          filename2
          id
        }
      }
      totalCount
    }
    allMedalXlsxPref {
      edges {
        node {
          area
          pref
          id
        }
      }
    }
    allMedalXlsxArea {
      edges {
        node {
          area
          id
        }
      }
    }
  }
`;
